import React from "react";

import Blog from "components/Blog";

class BlogPage extends React.PureComponent {
  render() {
    return <Blog />;
  }
}

export default BlogPage;
