import APIHandler from "./apiHandler";

import { API_ROUTES } from "./const";

const formApi = {
  getMyFroms: () =>
    APIHandler.get({ url: API_ROUTES.FORM }).then((response) => {
      return response;
    }),
  getFormById: ({ formId }) =>
    APIHandler.get({ url: `${API_ROUTES.FORM}${formId}` }).then((response) => {
      return response[0] ? response[0] : response;
    }),
  patchFormData: (payload) =>
    APIHandler.patch({
      url: API_ROUTES.FORM + payload.formId,
      body: { formData: payload },
    }).then((response) => {
      return response;
    }),
  postFormData: (payload) =>
    APIHandler.post({
      url: API_ROUTES.FORM,
      body: payload,
    }).then((response) => {
      return response;
    }),
  shareForm: ({ formId, email }) =>
    APIHandler.post({
      url: `${API_ROUTES.FORM}${formId}/share`,
      body: { email },
    }).then((response) => {
      return response;
    }),
  getActiveForms: (page = 0) =>
    APIHandler.get({ url: `${API_ROUTES.FORM}active?page=${page}` }).then((response) => {
      return {
        forms: response.forms,
        total: response.total
      };
    }),
  getInactiveForms: (page = 0) =>
    APIHandler.get({ url: `${API_ROUTES.FORM}inactive?page=${page}` }).then((response) => {
      return {
        forms: response.forms,
        total: response.total
      };
    }),
  getSummary: ({formId}) =>
      APIHandler.get({ url: `${API_ROUTES.FORM}${formId}/analyze` }).then((response) => {
        return response;
      }),
};

export default formApi;
