const META_TAGS = {
    "/register": {
        title: "Register - Central Feedback",
        description: "Create an account on Central Feedback to start collecting feedback.",
        keywords: "register, sign up, Central Feedback, feedback management, feedback forms",
        image: "socialpreview.png"
    },
    "/login": {
        title: "Login - Central Feedback",
        description: "Create an account on Central Feedback to start collecting feedback.",
        keywords: "login for free online form builder, Central Feedback, free online form builder, feedback management, feedback forms",
        image: "socialpreview.png"
    },
    "/": {
        title: "Central Feedback - Home",
        description: "Build simple yet professional online forms around your brand.",
        keywords: "online form builder, feedback form builder, feedback management, feedback forms, personal branding",
        image: "socialpreview.png"
    },
    
    "/blog": {
        title: "Blog - Central Feedback",
        description: "Articles about the importance of feedback and building online forms to gather feedback.",
        keywords: "online form builder blogs, online form templates, free online form builder, feedback management, feedback forms",
        image: "socialpreview.png"
    },
    // Add more routes and their meta tags as needed
};

export default META_TAGS;
