import React from "react";

import "./how-it-works.scss";

import asset1 from "./assets/asset1.svg";
import asset2 from "./assets/asset2.svg";
import asset3 from "./assets/asset3.svg";

const HowItWorks = () => {
  return (
    <section className="hiw hiw__wrapper">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Using Central Feedback</h2>
            <p>
              Understand your audience better by gathering high value feedback
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <img
              src={asset1}
              alt="create online form"
              className="hiw__illustration"
            />
            <h4>Create your feedback form</h4>
            <p>
              Easily design custom forms from scratch to collect feedback and
              insights tailored to your needs.
            </p>
          </div>
          <div className="col">
            <img
              src={asset2}
              alt="share form via QR"
              className="hiw__illustration"
            />
            <h4>Share it with your audience</h4>
            <p>
              Share your forms effortlessly via a URLs or a QR codes for easy
              access.
            </p>
          </div>
          <div className="col">
            <img
              src={asset3}
              alt="analyze form responses"
              className="hiw__illustration"
            />
            <h4>Collect and analyse reponses </h4>
            <p>
              You can view the gathered responses into one place, and get an AI
              summary in one click.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
