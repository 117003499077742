const amplitude = window.amplitude;

const f = () => {

    if (window.location.origin.includes('centralfeedback.com') && !window.location.origin.includes('staging')) {

        if (window.localStorage.getItem("IGNORE-AMPLITUDE")) {
            return false;
        }
        setTimeout(() => {
            amplitude?.init('30308ece02ff58fb16aea85b53f5a626', {
                serverZone: "EU", "autocapture": true
            });
        }, 100)
    }

}
f()

export default amplitude;