import React from "react";
import { Link, useLocation } from "react-router-dom";
import LogoWhite from "assets/logo-white.svg";

import { useSelector } from "react-redux";
import "./Footer.scss";

const Footer = ({ isAuthenticated }) => {
  const user = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (pathname.includes(user?.profile?.slug) && user?.profile?.rank === "3") {
    return (
      <footer className="authenticated-footer">
        <div className="container">
          Form powered by <a href="https://centralfeedback.com">Central Feedback</a>
        </div>
      </footer>
    );
  }

  if (isAuthenticated) {
    return (
      <footer className="authenticated-footer">
        <div className="container">
          Copyright © 2025 Central Feedback | All Rights Reserved
        </div>
      </footer>
    );
  }

  return (
    <footer>
      <div className="main-footer container">
        <div className="copy">
          <img className="logo" src={LogoWhite} alt="logo" /> <br />
          <span
            style={{ color: "white", cursor: "pointer" }}
            onClick={scrollToTop}
          >
            Back to the top
          </span>
          <br />
        </div>
        <div className="links use-case-links">
          <span>Use Cases</span>
          <Link to="/use-case/trainers">For Trainers</Link>
          <Link to="/use-case/teachers">For Teachers</Link>
          <Link to="/use-case/professionals">For Professionals</Link>
          <Link to="/use-case/event-organizers">For Event Organizers</Link>
        </div>
        <div className="links">
          <span>Resources</span>
          <Link to="/blog#top">Blog</Link>
          <Link to="/pricing#top">Pricing</Link>
          <Link to="/paulghiran/21#top">Give Us Feedback</Link>
        </div>
      </div>
      <div className="copy">
        Copyright © 2025 Central Feedback | All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
