import React from "react";
import Form from "components/shared/Form/form";

import "../Login/login.scss";

const registerFormFields = [
  {
    type: "text",
    name: "firstName",
    label: "First Name",
    errorMessage: "Invalid name",
    required: true,
  },
  {
    type: "text",
    name: "lastName",
    label: "Last Name",
    errorMessage: "Invalid name",
    required: true,
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    errorMessage: "Invalid Email",
    required: true,
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    errorMessage: "Invalid Password",
    required: true,
  },
  {
    type: "checkbox",
    name: "tac",
    label: "Terms And Conditions",
    required: true,
    explainer: (
      <label htmlFor="tac">
        I agree to the{" "}
        <a href="/terms-and-conditions">
          <b>terms and conditions</b>
        </a>
        .
      </label>
    ),
  },
];

const RegisterForm = ({
  userData,
  setUserData,
  doPostRegister,
  errors,
  minimal,
  customText,
}) => {
  const fields = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      width: "half",
      errorMessage: "Invalid name",
      required: true,
    },
    {
      type: "text",
      width: "half",
      name: "lastName",
      label: "Last Name",
      errorMessage: "Invalid name",
      required: true,
    },
    {
      type: "email",
      name: "email",
      label: "Email",
      errorMessage: "Invalid Email",
      required: true,
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      errorMessage: "Invalid Password",
      required: true,
    },
    {
      type: "checkbox",
      name: "tac",
      label: "Terms And Conditions",
      required: true,
      explainer: (
        <label htmlFor="tac">
          I agree to the{" "}
          <a href="/terms-and-conditions">
            <b>terms and conditions</b>
          </a>
          .
        </label>
      ),
    },
  ];

  const onSubmit = (formData) => {
    setUserData?.(formData);
    doPostRegister(formData);
  };

  return (
    <div className="">
      {!minimal && (
        <>
          <h3>Create an account</h3>
          <p>
            {customText ||
              "Just a few quick steps, and you're all set to start collecting feedback and growing your network"}
          </p>
        </>
      )}
      <Form
        name="register-form"
        fields={fields}
        onSubmit={onSubmit}
        values={userData}
        onChange={setUserData}
        formName=""
        buttonSize="large"
        submitOnEnter={true}
        buttonText={minimal ? "Create Account & Save Form" : "Create Account"}
      />
      {!!errors?.length && <div className="alert alert-danger">{errors}</div>}
    </div>
  );
};

export default RegisterForm;
