import React, { Suspense } from "react";
import AppRouter from "./components/AppRouter";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";


function App() {
  return (
    <Suspense fallback={<div style={{ textAlign: 'center' }}>Loading...</div>}>
      <AppRouter />
    </Suspense>
  );
}

export default App;
