import React, { useState, useEffect, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import "./PricingPage.scss";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import plansAPI from "../api/plansApi";
import Loader from "../components/shared/Loader"; // Add this import
import updateMetaTags from "helpers/meta";

const PLANS = [
  { name: "Premium", price: "$4.99/mo" },
  { name: "Professional", price: "$9.99/month" },
  { name: "Organization", price: "$19.99/month" },
];

const CheckoutForm = lazy(() => import("../components/CheckoutForm"));

const PricingPage = () => {
  const user = useSelector((state) => state?.user?.data || { rank: 0 });
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    plansAPI
      .getPlans()
      .then((data) => setPlans(data))
      .finally(() => setIsLoading(false));

    updateMetaTags({
      title: "Central Feedback Pricing",
      description:
        "Creating your first forms with Central Feedback is Free - But if you need more usage, pricing starts at $1/mo.",
    });
  }, []);

  const handleUpgradeClick = async (plan) => {
    if (!stripePromise) {
      const { loadStripe } = await import('@stripe/stripe-js');
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
    }
    setSelectedPlan(plan);
  };

  const upgradeOrSignUp = (user, plan) => {
    const OPERATION_TYPE =
      +user.rank == plan.rank
        ? "Current"
        : +user.rank < plan.rank
        ? "Upgrade"
        : "Downgrade";

    const BUTTON_LABELS = {
      Current: "Current Plan",
      Upgrade: "Upgrade to " + plan.name,
      Downgrade: "Contact Support",
    };

    const BUTTON_ACTIONS = {
      Current: () => {},
      Upgrade: () => handleUpgradeClick(plan),
      Downgrade: () => (window.location.href = "/feedback/95"),
    };

    const BUTTON_CLASSES = {
      Current: "button ternary",
      Upgrade: "button primary",
      Downgrade: "button secondary",
    };

    return user?.id
      ? {
          buttonText: BUTTON_LABELS[OPERATION_TYPE],
          buttonLink: "/plans",
          onClick: BUTTON_ACTIONS[OPERATION_TYPE],
          buttonClass: BUTTON_CLASSES[OPERATION_TYPE],
        }
      : {
          buttonText: "Get Started",
          buttonLink: "/register",
          buttonClass: "button",
          buttonClass: BUTTON_CLASSES[OPERATION_TYPE],
        };
  };

  const freePlan = [
    {
      name: "Basic",
      price: "$0",
      features: ["5 Online Forms", "100 Responses", "QR Code Sharing 🔗"],
      buttonText: "Get Started",
      buttonLink: "/register",
    },
  ];

  return (
    <div className="pricing-page">
      <div className="">
        <h1>Pricing Plans</h1>
        {isLoading ? (
          <Loader size="medium" />
        ) : (
          <div className="pricing-plans">
            {plans.map((plan, index) => {
              const buttonProps = upgradeOrSignUp(user, plan);
              return (
                <div key={index} className="pricing-plan">
                  <div>
                    <h2>{plan.name}</h2>
                    <p className="price">
                      {" "}
                      {plan.fullPrice ? <s>{plan.fullPrice} </s> : ""}
                      {plan.price}
                    </p>
                    <ul>
                      {plan.features.map((feature, idx) => (
                        <li key={idx}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                  {user?.id ? (
                    <button
                      className={`button ${buttonProps.buttonClass}`}
                      onClick={buttonProps.onClick}
                    >
                      {buttonProps.buttonText}
                    </button>
                  ) : (
                    <Link to={buttonProps.buttonLink} className="button">
                      {buttonProps.buttonText}
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="extra">
        For any other usage plans like a different combination of forms or
        responses per form, please <a href="/feedback/95">get in touch</a>.
      </div>
      {selectedPlan !== null && stripePromise && (
        <div className="checkout-modal">
          <div className="checkout-modal__content">
            <span className="close" onClick={() => setSelectedPlan(null)}>
              &times;
            </span>
            <Suspense fallback={<Loader size="medium" />}>
              <Elements stripe={stripePromise}>
                <CheckoutForm plan={selectedPlan} plans={plans} />
              </Elements>
            </Suspense>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingPage;
