import React, { useState } from "react";
import { Link, Links, useLocation } from "react-router-dom";

import { sortedRoutes } from "constants/appRoutes";
import NavItem from "./NavItem/NavItem";

import logout from "./assets/logout.png";
import settings from "./assets/settings.png";

import "./nav.scss";

import Logo from "assets/logo.svg";
import LogoWhite from "assets/logo-white.svg";
import LogoHorizontal from "assets/logo-horizontal.svg";
import { useSelector } from "react-redux";

const Nav = ({ isLoggedIn, user, extraClass }) => {
  const userState = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const isInverted =
    pathname.includes("/login") || pathname.includes("/register");

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const overwriteLogo =
    extraClass == "nav-outer" && userState?.profile?.rank == "3";

  let navClass = "";

  if ((pathname === "" || pathname === "/") && !isLoggedIn) {
    navClass = "home-nav";
  }

  if (isInverted) {
    navClass = "inverted-nav";
  }

  if (pathname.includes("/business-card")) {
    navClass = "no-print";
  }

  const loggedInKey = isLoggedIn ? "loggedIn" : "loggedOut";

  return (
    <>
      <nav
        className={`main-nav ${navClass || ""} ${extraClass || ""} ${
          overwriteLogo ? "overwrite-logo" : ""
        } ${user?.verificationCode ? "needs-verification" : ""} ${
          isLoggedIn ? "" : "logged-out"
        } `}
      >
        <div className="">
          <div className="main-nav__logo">
            <Link to="/" name="home">
              <img
                src={
                  extraClass == "nav-outer"
                    ? overwriteLogo
                      ? userState?.profile?.avatar
                      : LogoHorizontal
                    : isInverted
                    ? LogoWhite
                    : Logo
                }
                className={overwriteLogo ? "user-defined logo-img" : "logo-img"}
                alt="logo"
              />
            </Link>
          </div>
          <ul className={`main-nav__menu ${!isLoggedIn ? "align-right" : ""}`}>
            {sortedRoutes.map((route) =>
              !route?.role || route?.role <= user.role ? (
                <>
                  <NavItem
                    className={
                      route?.labels[loggedInKey]
                        ? route?.path == pathname
                          ? "current"
                          : ""
                        : ""
                    }
                    pathname={pathname}
                    key={route.path}
                    route={route}
                    isLoggedIn={isLoggedIn}
                    user={user}
                  />
                </>
              ) : (
                ""
              )
            )}
          </ul>

          <label
            className="main-nav__icon"
            style={{ backgroundImage: `url(${user?.avatar})` }}
            htmlFor="nav-hidden-input"
            onClick={toggleDrawer}
          >
            <span>Menu</span>
          </label>
          <input type="text" name="focus-hack" id="nav-hidden-input" />
          <label htmlFor="nav-hidden-input" className="main-nav__dropdown">
            <Link className="popdown-links" to="/settings">
              <img src={settings} alt="" /> Account Settings
            </Link>
            <Link className="popdown-links" to="/logout">
              <img src={logout} alt="" /> Logout
            </Link>
          </label>
        </div>
        {user.verificationCode && (
          <div className="alert alert-warning verify-account" role="alert">
            Please verify your email.
          </div>
        )}
      </nav>

      <div className={`drawer-menu ${isDrawerOpen ? "open" : ""}`}>
        <img
          src={overwriteLogo ? userState?.profile.avatar || Logo : Logo}
          className="drawer-logo"
          alt="logo"
        />
        <span className="close-btn" onClick={toggleDrawer}>
          &times;
        </span>
        <ul className="drawer-menu__list">
          {sortedRoutes.map((route) => {
            {
              return !route?.role || route?.role <= user.role ? (
                <li key={route.path}>
                  <Link to={route.path} onClick={toggleDrawer}>
                    {route.labels[isLoggedIn ? "loggedIn" : "loggedOut"]}
                  </Link>
                </li>
              ) : (
                ""
              );
            }
          })}
        </ul>
        <div className="logout">
          <Link to="/logout">Log Out</Link>
        </div>
      </div>
      {isDrawerOpen && (
        <div className="drawer-overlay" onClick={toggleDrawer}></div>
      )}
    </>
  );
};

export default Nav;
