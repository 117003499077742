import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";
import userAPI from "api/userApi";

import { requestLogin, unsetToken } from "./auth";
import { addToast } from "./toast";
import { setError, unsetError } from "./errors";

export function setUserData(data) {
  return {
    type: ACTION_TYPES.USER.SET_DATA,
    payload: data,
  };
}

export function setUserProfile(data) {
  return {
    type: ACTION_TYPES.USER.SET_PROFILE,
    payload: data,
  };
}

export function unsetData() {
  return {
    type: ACTION_TYPES.USER.UNSET_DATA,
  };
}

export function setStatus(status) {
  return {
    type: ACTION_TYPES.USER.SET_STATUS,
    payload: status,
  };
}

export function setProfileStatus(status) {
  return {
    type: ACTION_TYPES.USER.SET_PROFILE_STATUS,
    payload: status,
  };
}

export function requestUserDataIfToken() {
  return (dispatch) => {
    if (localStorage.getItem("token")) {
      return dispatch(requestUserData());
    }

    return dispatch(setStatus(STATUS_TYPES.FAILED));
  };
}

export function requestUserData() {
  return (dispatch) => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    return userAPI.fetchUserData().then(
      (jsonPayload) => {
        dispatch(setUserData(jsonPayload));

        return dispatch(setStatus(STATUS_TYPES.LOADED));
      },
      (response) => {
        dispatch(unsetToken());
        dispatch(setStatus(STATUS_TYPES.FAILED));

        response.then((payload) => {
          return dispatch(
            setError({ type: "user", errors: [payload.message] })
          );
        });
      }
    );
  };
}

export function postUserData(payload) {
  return (dispatch) => {
    dispatch(unsetError({ type: "register-form" }));

    return userAPI.postUserData(payload).then(
      (jsonPayload) => {

        setTimeout(() => {
          dispatch(requestLogin({ email: payload.email, password: payload.password }));
          setTimeout(() => {
            window.location.pathname = '/forms';
          });
        }, 2000)

        return dispatch(
          addToast({
            type: "success",
            title: "Success!",
            text: "Logging you in ...",
          }))
      },
      (response) => {
        response.then((payload) => {
          return dispatch(
            setError({
              type: "register-form",
              errors: [
                payload
                  ? payload.code == 409
                    ? "Some of your data is already in our database:" +
                    payload.fields
                    : "Incorrect data; Please double check your input"
                  : "Unknown error",
              ],
            })
          );
        });
      }
    );
  };
}

export function patchUserData(payload) {
  return (dispatch) => {
    return userAPI.patchUserData(payload).then(
      (jsonPayload) => {
        dispatch(requestUserData());
      },
      (response) => {
        response.then((payload) => {
          setError({ type: "settings-form", errors: [payload.message] });
        });
      }
    );
  };
}

export function getUserProfile(payload) {
  return (dispatch) => {
    dispatch(setProfileStatus(STATUS_TYPES.PENDING));

    return userAPI.getProfile(payload).then(
      (jsonPayload) => {
        dispatch(setUserProfile(jsonPayload));

        return dispatch(setProfileStatus(STATUS_TYPES.LOADED));
      },
      (error) => {
        dispatch(setProfileStatus(STATUS_TYPES.FAILED));

        return dispatch(
          setError({ type: "profile", errors: [{ code: 404 }] })
        );
      }
    );
  };
}
