import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Nav from "../Nav";
import Loader from "components/shared/Loader";
import Toaster from "components/shared/Toaster";
import Footer from "../Footer/Footer";

import APP_ROUTES from "constants/appRoutes";
import STATUS_TYPES from "constants/statusTypes";
import amplitude from "shared/analytics";
import { useSelector } from "react-redux";

const AppRouter = ({ doGetUserData, userStatus, user }) => {
  const isLoggedIn = useSelector((state) => !!state?.user?.data?.id);
  const [hasShownLoader, setHasShownLoader] = useState(false);

  const extraClasses = [];
  const [hasCheckedAuthentication, setHasCheckedAuthentication] =
    useState(false);

  useEffect(() => {
    doGetUserData();
  }, [doGetUserData, isLoggedIn]);

  useEffect(() => {
    setTimeout(() => {
      setHasShownLoader(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (
      userStatus === STATUS_TYPES.LOADED ||
      userStatus === STATUS_TYPES.FAILED
    ) {
      setHasCheckedAuthentication(true);

      try {
        const amplitudeInstance = amplitude.getInstance
          ? amplitude.getInstance()
          : amplitude;
        const identify = new amplitude.Identify();

        identify.set("plan", user.plan || "DEFAULT_FREE");
        identify.add("login_count", 1);

        // Retrieve guest ID from localStorage
        let guest_id = localStorage.getItem("guest_id");
        if (!guest_id) {
          guest_id = `guest_${Math.floor(Math.random() * 10000)}`;
          localStorage.setItem("guest_id", guest_id);
        }

        if (!user?.id) {
          // Track as guest
          identify.set("email", "GUEST");
          amplitudeInstance.set("GUEST_ID", guest_id);
        } else {
          // Track as logged-in user while **preserving the guest's data**
          identify.set("email", user.email);

          // Get the current device ID to prevent tracking a new user
          const existingDeviceId = amplitudeInstance.getDeviceId();

          // Merge guest user with logged-in user
          amplitudeInstance.setUserId(`user_${user.id}`); // Switch to the logged-in user
          amplitudeInstance.setDeviceId(existingDeviceId); // Keep the same device ID

          // **Important:** Alias guest to user to track them as one entity
          amplitudeInstance.logEvent("USER_MERGED", {
            previous_guest_id: guest_id,
            new_user_id: `user_${user.id}`,
          });
        }

        amplitudeInstance.identify(identify);
      } catch (e) {
        console.error("Amplitude identification error:", e);
      }
    }
  }, [userStatus, hasCheckedAuthentication, user, isLoggedIn]);

  const isAuthenticated = hasCheckedAuthentication && !!user?.id;

  return (
    <Router>
      <InnerAppRouter
        hasCheckedAuthentication={hasCheckedAuthentication}
        hasShownLoader={hasShownLoader}
        isAuthenticated={isAuthenticated}
        user={user}
        isLoggedIn={isLoggedIn}
      />
    </Router>
  );
};

const InnerAppRouter = ({
  isAuthenticated,
  user,
  isLoggedIn,
  hasCheckedAuthentication,
  hasShownLoader,
}) => {
  const location = useLocation();
  const isFixed = location.pathname.includes("form-builder");

  const updateMetaTags = (route) => {
    if (route.meta) {
      document.title = route.meta.title;

      const setMetaTag = (name, content) => {
        let tag = document.querySelector(`meta[name="${name}"]`);
        if (!tag) {
          tag = document.createElement("meta");
          tag.setAttribute("name", name);
          document.head.appendChild(tag);
        }
        tag.setAttribute("content", content);
      };

      const setPropertyTag = (property, content) => {
        let tag = document.querySelector(`meta[property="${property}"]`);
        if (!tag) {
          tag = document.createElement("meta");
          tag.setAttribute("property", property);
          document.head.appendChild(tag);
        }
        tag.setAttribute("content", content);
      };

      setMetaTag("description", route.meta.description);
      setMetaTag("keywords", route.meta.keywords);

      // Open Graph tags
      setPropertyTag("og:title", route.meta.title);
      setPropertyTag("og:description", route.meta.description);
      setPropertyTag("og:type", "website");
      setPropertyTag("og:url", window.location.href);
      setPropertyTag("og:image", route.meta.image || "default-image-url");

      // Twitter Card tags
      setMetaTag("twitter:card", "summary_large_image");
      setMetaTag("twitter:title", route.meta.title);
      setMetaTag("twitter:description", route.meta.description);
      setMetaTag("twitter:image", route.meta.image || "default-image-url");
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentRoute = APP_ROUTES.find((route) => route.path === currentPath);
    scrollToTop();

    if (currentRoute) {
      updateMetaTags(currentRoute);
    }
    if (amplitude) {
      const track = amplitude.track ? amplitude.track : () => {};
      track("PAGE_VIEW", { path: location.pathname, title: document.title });
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const currentRoute = APP_ROUTES.find(
    (route) => route.path === location.pathname
  );
  const extraClass = currentRoute
    ? currentRoute.extraClass || ""
    : !location.pathname.includes("use-case") &&
      !location.pathname.includes("/blog/") &&
      !isLoggedIn
    ? "nav-outer"
    : "";

  if (!hasCheckedAuthentication || !hasShownLoader)
    return <Loader size="full-screen" type="full" />;

  return (
    <div
      id="top"
      className={`app ${isFixed ? "fixed" : ""} ${
        isAuthenticated ? "authenticated" : ""
      } ${extraClass}`}
    >
      <Nav user={user} extraClass={extraClass} />

      <div className="min-height-container">
        <Toaster />
        <Routes>
          {APP_ROUTES.map((route) => {
            const Component = isLoggedIn
              ? route.components.loggedIn
              : route.components.loggedOut;
            return (
              <Route
                path={route.path}
                element={<Component />}
                key={route.path}
                {...(route.extraProps || {})}
              />
            );
          })}
        </Routes>
      </div>
      <Footer isAuthenticated={isAuthenticated} />
    </div>
  );
};

export default AppRouter;
