import React from "react";

import "./WhyUs.scss";
import { Link } from "react-router-dom";

import r1 from "./assets/r1.svg";
import r2 from "./assets/r2.svg";
import r3 from "./assets/r3.svg";
import r4 from "./assets/r4.svg";
import r5 from "./assets/r5.svg";
import r6 from "./assets/r6.svg";
import Cta from "./Cta";

const WhyUs = () => {
  return (
    <section className="why-us why-us__wrapper">
      <h2>Choosing the right feedback tool makes all the difference. </h2>
      <p>Here’s why our platform stands out:</p>
      <div className="container">
        <div className="why-us__reasons">
          <div className="why-us__reason">
            <img src={r1} alt="Click illustration" />
            <h4>Quick setup</h4>
            <p>
              Launch your feedback form in minutes with an intuitive, no-code
              interface - no technical expertise required.
            </p>
            <Link to="/register">Get started</Link>
          </div>
          <div className="why-us__reason">
            <img src={r2} alt="QR illustration" />
            <h4>Seamless sharing</h4>
            <p>
              Share via email, QR codes, or direct links, allowing your audience
              to provide feedback quickly and effortlessly with just a scan - no
              sign-up required.
            </p>
            <Link to="/register">Get started</Link>
          </div>
          <div className="why-us__reason">
            <img src={r3} alt="Device illustration" />
            <h4>Multi-device compatible</h4>
            <p>
              Optimized for seamless use on desktops, tablets, and smartphones,
              ensuring accessibility anywhere.{" "}
            </p>
            <Link to="/register">Get started</Link>
          </div>
          <div className="why-us__reason">
            <img src={r4} alt="Scaling illustration" />
            <h4>Scalable for any use case</h4>
            <p>
              From small classrooms to large conferences and corporate events,
              our platform scales to fit your needs seamlessly.{" "}
            </p>
            <Link to="/register">Get started</Link>
          </div>
          <div className="why-us__reason">
            <img src={r5} alt="Star illustration" />
            <h4>Real-time insights</h4>
            <p>
              Access real-time insights through a user-friendly interface and
              summarise the feedback using AI.
            </p>
            <Link to="/register">Get started</Link>
          </div>
          <div className="why-us__reason">
            <img src={r6} alt="Restart Icon" />
            <h4>Easy to start over</h4>
            <p>
              Once you're done with your form you can create the next one, one
              for each occasion.
            </p>
            <Link to="/register">Get started</Link>
          </div>
        </div>
      </div>

      <div className="container">
        <Cta />
      </div>
    </section>
  );
};

export default WhyUs;
