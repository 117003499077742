import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Homepage from "pages/HomePage";
import Button from "components/shared/Button";
import META_TAGS from "./metaTags";
import PricingPage from "pages/PricingPage";
import ArticlePage from "pages/ArticlePage";
import Blog from "pages/BlogPage";
import NewFormPage from "pages/NewFormPage";

// Lazy load components
const Login = lazy(() => import("pages/LoginPage"));
const Logout = lazy(() => import("pages/LogoutPage"));
const FormsPage = lazy(() => import("pages/FormsPage"));
const Settings = lazy(() => import("pages/SettingsPage"));
const Register = lazy(() => import("pages/RegisterPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));
const ResponsesPage = lazy(() => import("pages/ResponsesPage"));
const TermsAndConditions = lazy(() => import("pages/TermsAndConditions"));
const PlansPage = lazy(() => import("pages/PlansPage"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const VerificationPage = lazy(() => import("pages/VerificationPage"));
const AdminUsersPage = lazy(() => import("pages/AdminUsersPage"));
const BusinesCards = lazy(() => import("components/BusinessCard"));

/* This file controls both the router and the menu.
  Routes have components & labels for both logged-in and logged-out states.
  For private routes, a Redirect to '/' is recommended
  Paths with no label (for either loggedIn, loggedOut or both) will not get rendered in the menu
*/

const APP_ROUTES = [
  {
    order: 0,
    path: "/terms-and-conditions",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: TermsAndConditions,
      loggedOut: TermsAndConditions,
    },
  },
  {
    order: 0,
    path: "/privacy-policy",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: PrivacyPolicy,
      loggedOut: PrivacyPolicy,
    },
  },
  {
    order: 3,
    path: "/register",
    components: {
      loggedIn: () => <Navigate to="/" />,
      loggedOut: Register,
    },
    labels: { loggedIn: "", loggedOut: <Button className='get-started' text="Get started" variant="round" /> },
    meta: META_TAGS["/register"],
  },
  {
    order: 0,
    path: "<<AUTOREPLACE_SLUG>>",
    components: {
      loggedIn: Settings,
      loggedOut: Login,
    },
    labels: { loggedIn: "", loggedOut: "" },
    subRoutes: [
      {
        order: 0,
        path: "/settings",
        components: {
          loggedIn: Settings,
          loggedOut: Login,
        },
        labels: { loggedIn: "Settings", loggedOut: "" },
      },
    ]
  },
  {
    order: 2,
    path: "/settings",
    components: {
      loggedIn: Settings,
      loggedOut: Login,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/blog/:slug",
    components: {
      loggedIn: ArticlePage,
      loggedOut: ArticlePage,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/use-case/:slug",
    components: {
      loggedIn: ArticlePage,
      loggedOut: ArticlePage,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/feedback-for-trainers",
    components: {
      loggedIn: () => <ArticlePage slugOverwrite={"central-feedback-for-trainers"} />,
      loggedOut: () => <ArticlePage slugOverwrite={"central-feedback-for-trainers"} />,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 2,
    path: "/blog",
    components: {
      loggedIn: Blog,
      loggedOut: Blog,
    },
    labels: { loggedIn: "", loggedOut: "" },
    meta: META_TAGS["/blog"],
  },
  {
    order: 1,
    path: "/pricing",
    components: {
      loggedIn: PricingPage,
      loggedOut: PricingPage,
    },
    labels: { loggedIn: "Pricing", loggedOut: "Pricing" },
  },
  {
    order: 1,
    path: "/login",
    components: {
      loggedIn: () => <Navigate to="/" />,
      loggedOut: Login,
    },
    labels: { loggedIn: "", loggedOut: "Log In" },
    meta: META_TAGS["/login"],
  },
  {
    order: 9,
    path: "/logout",
    components: {
      loggedOut: () => <Navigate to="/" />,
      loggedIn: Logout,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/:slug/:formId?",
    components: {
      loggedIn: ProfilePage,
      loggedOut: ProfilePage,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: () => <Navigate to="/forms" />,
      loggedOut: Homepage,
    },
    meta: META_TAGS["/"],
  },
  {
    order: 0,
    path: "/forms",
    labels: { loggedIn: "Forms", loggedOut: "" },
    components: {
      loggedIn: FormsPage,
      loggedOut: () => <Navigate to="/" />,
    },
  },
  {
    order: 0,
    path: "/plans",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: PlansPage,
      loggedOut: () => <Navigate to="/" />,
    },
  },
  {
    order: 0,
    path: "/business-card",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: BusinesCards,
      loggedOut: () => <Navigate to="/" />,
    },
  },
  {
    order: 0,
    path: "/form-builder/:formId?",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: NewFormPage,
      loggedOut: () => <Navigate to="/" />,
    },
  },
  {
    order: 0,
    path: "/form-builder/:formId?/:template?",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: NewFormPage,
      loggedOut: NewFormPage, // Allow non-logged in users
    },
  },
  {
    order: 0,
    path: "/form-responses/:formId",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: ResponsesPage,
      loggedOut: () => <Navigate to="/" />,
    },
  },
  {
    order: 1,
    path: "/NotFound",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: () => (
        <div className="home">
          404, not found.
          <a href="/">Home</a>
        </div>
      ),
      loggedOut: () => (
        <div className="home">
          404, not found.
          <a href="/">Login</a>
        </div>
      ),
    },
  },
  {
    order: 0,
    path: "/verify/:userId/:verificationCode",
    components: {
      loggedIn: VerificationPage,
      loggedOut: VerificationPage,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/admin-users",
    labels: { loggedIn: "Users", loggedOut: "" },
    components: {
      loggedIn: AdminUsersPage,
      loggedOut: () => <Navigate to="/" />,
    },
    meta: META_TAGS["/admin-users"],
    role: 1, // Only visible to admin users
  },
];

const sortedRoutes = APP_ROUTES.concat().sort((a, b) => a.order - b.order);

export { sortedRoutes };

export default APP_ROUTES;
