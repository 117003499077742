import React from "react";

import "./cta.scss";
import UIButton from "components/shared/Button";

const Cta = () => {
  return (
    <aside className="home-cta__cta">
      <h2>Get better feedback today!</h2>
      <p className="home-cta__cta-description">
        Stop guessing - start improving.
      </p>
      <UIButton
        className="home-cta__cta-button"
        type="primary"
        action="link"
        to="/register"
        text="Get Started For Free"
        id="cta"
      />
    </aside>
  );
};

export default Cta;
