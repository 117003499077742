import React from "react";
import { useParams, useLocation } from "react-router-dom";
import Article from "components/Blog/Article";

const ArticlePage = ({ slugOverwrite }) => {
  const { slug } = useParams();
  const { pathname } = useLocation();

  return (
    <Article
      slug={slugOverwrite ? slugOverwrite : slug}
      type={pathname.includes("use-case") ? "use-case" : "article"}
    />
  );
};

export default ArticlePage;
