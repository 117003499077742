import React from "react";

import "./button.scss";
import { Link } from "react-router-dom";
import amplitude from "shared/analytics";

import { Button } from "react-bootstrap";
const UIButton = ({
  text,
  type = "primary",
  to,
  action,
  onClick,
  className,
  disabled = false,
  size,
  variant,
  id = "",
  icon,
  target,
}) => {
  const trackButton = () => {
    amplitude?.track({
      event_type: "CLICK",
      properties: {
        path: window.location.pathname,
        text: text,
      },
    });
  };

  const buttonContent = (
    <>
      {icon && (
        <span className="button-icon">
          <img src={icon} />
        </span>
      )}
      {text}
    </>
  );

  if (action === "link") {
    return (
      <Link to={to} className="link-button" target={target || null}>
        <Button
          {...(id ? { id } : {})}
          type="button"
          className={`button ${type} disabled-${disabled} ${className || ""} ${variant || ""
            } ${size || ""}`}
          onClick={
            onClick
              ? (e) => {
                trackButton();
                e.preventDefault();
                onClick();
              }
              : () => {
                trackButton();
              }
          }
        >
          {buttonContent}
        </Button>
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={`button ${type} disabled-${disabled} ${className || ""} ${variant || ""
        } ${size || ""}`}
      onClick={
        onClick
          ? (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            trackButton();
            onClick();
          }
          : () => { }
      }
      disabled={disabled}
    >
      {buttonContent}
    </button>
  );
};

export default UIButton;
