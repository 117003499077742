import React, { useState, useEffect } from "react";
import Box from "components/shared/Box";
import Loader from "../../shared/Loader";
import updateMetaTags from "helpers/meta";
import NotFoundImage from "./article-404.jpg";
import UIButton from "components/shared/Button";
import Cta from "components/Home/Cta";

const fetchArticle = async (slug) => {
  try {
    const response = await fetch(
      `https://hosting.azasoftware.com/wp-json/wp/v2/posts?slug=${slug}&_embed&acf_format=standard`
    );

    const articles = await response.json();
    return articles.length > 0 ? articles[0] : null;
  } catch (error) {
    console.error("Error fetching article:", error);
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${daySuffix(day)} ${month}. ${year}`;
};

const Article = ({ slug, type }) => {
  const [article, setArticle] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const getArticle = async () => {
      const articleData = await fetchArticle(slug);

      if (!articleData) {
        updateMetaTags({
          title: "Article not found - Central Feedback",
        });

        setHasLoaded(true);
        return setArticle(null);
      }
      if (isMounted) {
        setArticle(articleData);
        updateMetaTags({
          title: `${articleData?.title?.rendered} - Central Feedback`,
          description: articleData?.acf?.meta_description || "",
        });
      }

      setHasLoaded(true);
    };

    getArticle();
    return () => {
      isMounted = false;
    };
  }, [slug]);

  if (!hasLoaded) {
    return (
      <>
        <Loader size="largest" />
      </>
    );
  }

  if (!article) {
    return (
      <article className="article">
        <div className="article__header">
          <p className="date">Posted: 26th Feb. 2025</p>
          <h1 className="container">404: A story of not found pages.</h1>
          <p className="author">Written by the Central Feedback Team</p>
        </div>
        <main className="container">
          <img
            src={NotFoundImage}
            alt="Woman looking through binoculars - Photo by @chaseelliottclark on Unsplash"
          />
          <h2>
            Every once in a while, our search for wisdom can lead us astray
          </h2>
          <p>
            It might be a broken link in this case, or maybe an error. When that
            happens, our best bet would be to start over by reading{" "}
            <a href="/blog">Articles on Central Feedback</a> or just starting
            out and building an online form on by{" "}
            <a href="/register">Signing Up</a> on Central Feedback.
          </p>
          <h3>So what is a broken link?</h3>
          <p>
            A broken link is a hyperlink that no longer works, leading to that
            well-known <b>404 (page not found) error</b> that we've all probably
            heard about in the online space.
          </p>
          <p>
            A broken link might be caused by an article or page having moved to
            a different location (URL) or just being deleted. This can mean the
            team has changed the URL of the page you're looking for to something
            more suggestive, for example, or maybe a change in structure might
            have occured on the website.
          </p>
          <p>
            Sometimes it can be something as simple as copying the wrong URL or
            misstyping it.
          </p>
          <h3>What should you do now?</h3>
          <p>
            You can read the articles on the{" "}
            <a href="/blog">
              <b>Central Feedback blog</b>
            </a>{" "}
            or maybe try our{" "}
            <a href="/form-builder/new/general_feedback">
              <b>free form builder</b>{" "}
            </a>!
          </p>
        </main>
        <div className="container" style={{ marginBottom: "80px" }}>
          <Cta />
        </div>
      </article>
    );
  }

  return (
    <article className={`article__wrapper ${type}`}>
      <div className="article__header">
        {article?.date && type != "use-case" && (
          <p className="date">Posted: {formatDate(article.date)}</p>
        )}
        <h1 className="container">{article.title.rendered}</h1>
        {article?._embedded?.author && type != "use-case" && (
          <p className="author">
            Written by {article?._embedded?.author[0]?.name}
          </p>
        )}{" "}
      </div>
      <main className="article container">
        {article._embedded && article._embedded["wp:featuredmedia"] && (
          <div
            src={article._embedded["wp:featuredmedia"][0].source_url}
            alt={article.title.rendered}
            className="article__image"
          />
        )}
        <main dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
      </main>
      <aside className="article__cta">
        <h2>{article?.acf?.cta_title}</h2>
        <p className="article__cta-description">
          {article?.acf?.cta_description ||
            "Join us to improve your feedback process."}
        </p>
        <UIButton
          className="article__cta-button"
          type="primary"
          action="link"
          to={article?.acf?.cta_button_url}
          text={article?.acf?.cta_button_text}
        />
      </aside>
    </article>
  );
};

export default Article;
