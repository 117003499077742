import React, { useState, useEffect } from "react";
import Box from "components/shared/Box";
import Loader from "../shared/Loader";

const fetchPosts = async () => {
  try {
    const response = await fetch(
      "https://hosting.azasoftware.com/wp-json/wp/v2/posts?categories=5&_embed"
    );

    return await response.json();
  } catch (error) {
    console.error("Error fetching posts:", error);
  }
};

const Blog = ({ type, className }) => {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    let isMounted = true;
    const getPosts = async () => {
      const posts = await fetchPosts();
      if (isMounted) {
        setArticles(posts);
      }
    };
    getPosts();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <article className="blog blog">
      <div className="blog__header">
        <p className="date">Articles</p>
        <h1 className="container">Welcome to the Central Feedback blog</h1>
        <p className="author">
          We post articles about building your own online forms for gathering
          feedback.
        </p>
      </div>
      <main className="container">
        {!articles || (articles.length === 0 && <Loader size="large" />)}
        {articles.map((article) => (
          <a
            key={article.id}
            href={`/blog/${article.slug}`}
            className={`article-link ${
              type === "small" && "article-link--small"
            }`}
          >
            <article className="">
              {article._embedded && article._embedded["wp:featuredmedia"] && (
                <div
                  style={{
                    backgroundImage: `url(${article._embedded["wp:featuredmedia"][0].source_url})`,
                  }}
                  alt={article.title.rendered}
                  className="article-link__image"
                />
              )}
              <h2 className="article-link__title">{article.title.rendered}</h2>
            </article>
          </a>
        ))}
      </main>
    </article>
  );
};

Blog.defaultProps = {
  type: "",
  className: "",
};

export default Blog;
