import APIHandler from "./apiHandler";
import { API_ROUTES } from "./const";

const plansAPI = {
  getPlans: () =>
    APIHandler.get({ url: API_ROUTES.PLANS }).then((response) => {
      return response;
    }),
};

export default plansAPI;
