import React from "react";

import FormField from "./formField.jsx";

import Button from "components/shared/Button/index.js";

import propTypes from "prop-types";

import "./form.scss";
import amplitude from "shared/analytics.js";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldValues: this.getInitialValues(props.fields),
      validationState: {},
    };
  }

  componentDidMount() {
    if (this.props.submitOnEnter) {
      document.addEventListener("keydown", this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (this.props.submitOnEnter) {
      document.removeEventListener("keydown", this.handleKeyDown);
    }
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.submitForm();
    }
  };

  getInitialValues(fields) {
    const initialFieldValues = {};

    fields.forEach((field) => {
      initialFieldValues[field.name] = field.value || field.initialValue || "";
    });

    return initialFieldValues;
  }

  changeFieldValue = (fieldName, fieldValue) => {
    const fieldValues = this.props.values
      ? this.props.values
      : this.state.fieldValues;

    const newFV = { ...fieldValues, [fieldName]: fieldValue };

    this.setState({ fieldValues: newFV });

    if (this.props.onChange) {
      this.props.onChange(newFV);
    }
  };

  validateForm() {
    const { validationState } = this.state;
    const fieldValues = this.props.values
      ? this.props.values
      : this.state.fieldValues;

    let isValid = true;

    this.props.fields.forEach((field) => {
      const { name, required } = field;
      if (required && !fieldValues[name]) {
        validationState[name] = false;
        isValid = false;
      } else {
        validationState[name] = true;
      }
    });

    this.setState({
      validationState: { ...validationState },
    });

    return isValid;
  }

  submitForm = () => {
    const { onSubmit, name } = this.props;

    amplitude?.track({
      event_type: "SUBMIT_FORM",
      properties: {
        path: window.location.pathname,
        name: name,
      },
    });

    const fieldValues = this.props.values
      ? this.props.values
      : this.state.fieldValues;

    if (this.validateForm()) {
      onSubmit(fieldValues);
    }
  };

  renderField = (field) => {
    return (
      <FormField
        key={field.name}
        {...field}
        onChange={(fieldValue) => this.changeFieldValue(field.name, fieldValue)}
        isInvalid={this.state.validationState[field.name] === false}
        value={this.state.fieldValues[field.name]}
        variant={this.props.inputVariant}
        min={field.min}
        max={field.max}
        separatorText={field.separatorText}
      />
    );
  };

  render() {
    const {
      className,
      fields,
      formName,
      formDescription,
      disabled,
      onSubmit,
      buttonSize,
      submitButtonText = "Submit",
    } = this.props;
    const { validationState } = this.state;

    const fieldValues = this.props.values
      ? this.props.values
      : this.state.fieldValues;

    return (
      <div className={`form ${className}`}>
        {formName && <h4>{formName}</h4>}
        {formDescription && (
          <p className="form__description">{formDescription}</p>
        )}
        {fields.map((field, i) => this.renderField(field))}
        {onSubmit && (
          <Button
            onClick={disabled ? () => {} : this.submitForm}
            text={submitButtonText}
            size={buttonSize}
            disabled={disabled}
          />
        )}
      </div>
    );
  }
}

Form.defaultProps = {
  className: "",
  submitOnEnter: false,
};

Form.propTypes = {
  className: propTypes.string,
  fields: propTypes.arrayOf(propTypes.object),
  onSubmit: () => {},
  submitOnEnter: propTypes.bool,
};

export default Form;
