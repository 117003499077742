export const TEMPLATES = {
  blank: {
    name: "Start from scratch",
    description: "Create your own custom form from scratch.",
    questions: [],
  },
  customer_feedback: {
    name: "Customer Feedback",
    description:
      "Please take a minute to let us know how your experience went.",
    questions: [
      { type: "rating", label: "How satisfied were you with our product?" },
      {
        type: "short",
        label: "What do you like most about our product/service?",
      },
      {
        type: "long",
        label: "What do you like least about our product/service?",
      },
      {
        type: "dropdown",
        label: "Was your issue resolved?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      {
        type: "radio",
        label: "How can we improve our service?",
        options: [
          { value: "Improve response time" },
          { value: "Enhance product features" },
          { value: "Better customer support" },
          { value: "Other" },
        ],
      },
      { type: "short", label: "Any additional comments?" },
    ],
  },
  trainer_feedback: {
    name: "Trainer Feedback",
    description: "Provide feedback on the training session and the trainer.",
    questions: [
      { type: "long", label: "What did you like most about the session?" },
      { type: "long", label: "How can the training delivery be improved?" },
      { type: "rating", label: "Rate the trainer's subject knowledge " },
      {
        type: "radio",
        label: "Was the session engaging?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      { type: "short", label: "Any suggestions for future topics?" },
      {
        type: "rating",
        label: "How likely are you to recommend this session to a colleague ?",
      },
    ],
  },
  employee_feedback: {
    name: "Employee Feedback",
    description:
      "Share your thoughts on your work environment and job satisfaction.",
    questions: [
      {
        type: "rating",
        label: "How satisfied are you with your work environment?",
      },
      { type: "long", label: "What challenges are you facing in your role?" },
      { type: "short", label: "What support do you need to succeed?" },
      {
        type: "radio",
        label: "Would you recommend our company as a great workplace?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      { type: "rating", label: "How would you rate your work-life balance ?" },
      {
        type: "long",
        label: "Any suggestions for improving employee satisfaction?",
      },
    ],
  },
  post_event_feedback: {
    name: "Post-Event Feedback",
    description: "Give us your feedback on the event you attended.",
    questions: [
      { type: "rating", label: "Overall, how would you rate the event?" },
      { type: "long", label: "What did you like most about the event?" },
      { type: "long", label: "What could be improved for future events?" },
      {
        type: "dropdown",
        label: "How likely are you to attend our future events?",
        options: [
          { value: "Very likely" },
          { value: "Somewhat likely" },
          { value: "Not likely" },
        ],
      },
      { type: "short", label: "How did you hear about the event?" },
      { type: "long", label: "Any additional comments or suggestions?" },
    ],
  },
  csat_survey: {
    name: "Customer Satisfaction Survey",
    description: "Help us understand your satisfaction with our service.",
    questions: [
      {
        type: "rating",
        label: "Overall, how satisfied are you with our service ?",
      },
      { type: "long", label: "What do you like most about our service?" },
      { type: "long", label: "What do you think could be improved?" },
      { type: "scale", label: "How likely are you to recommend us?", min: 0, max: 10 },
      { type: "rating", label: "Rate the quality of our customer support " },
      { type: "short", label: "Any additional comments?" },
    ],
  },
  nps_survey: {
    name: "Net Promoter Score Survey",
    description: "Tell us how likely you are to recommend us to others.",
    questions: [
      { type: "scale", label: "How likely are you to recommend us?", min: 0, max: 10 },
      { type: "long", label: "What is the primary reason for your score?" },
      {
        type: "dropdown",
        label: "How frequently do you use our product/service?",
        options: [
          { value: "Daily" },
          { value: "Weekly" },
          { value: "Monthly" },
          { value: "Rarely" },
        ],
      },
      {
        type: "radio",
        label:
          "Would you consider purchasing additional products/services from us?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
    ],
  },
  ces_survey: {
    name: "Customer Effort Score Survey",
    description: "Rate the effort required to resolve your issue.",
    questions: [
      { type: "rating", label: "How easy was it to resolve your issue?" },
      {
        type: "long",
        label: "Please explain what made your experience easy or difficult.",
      },
      {
        type: "radio",
        label: "Did you find the support team helpful?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      {
        type: "number",
        label: "Time taken to resolve your issue (in minutes)",
      },
      {
        type: "rating",
        label: "How would you rate the clarity of instructions provided ?",
      },
      { type: "long", label: "Any suggestions to reduce effort on your side?" },
    ],
  },
  product_feedback: {
    name: "Product Feedback",
    description: "Provide feedback on your experience with our product.",
    questions: [
      {
        type: "rating",
        label: "How would you rate your overall experience with our product ?",
      },
      {
        type: "radio",
        label: "Do you plan to continue using our product?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      {
        type: "dropdown",
        label: "How often do you use our product?",
        options: [
          { value: "Daily" },
          { value: "Weekly" },
          { value: "Monthly" },
          { value: "Rarely" },
        ],
      },
      { type: "long", label: "What do you like most about our product?" },
      {
        type: "long",
        label: "What frustrates you the most about our product?",
      },
      {
        type: "long",
        label: "What new features or improvements would you like to see?",
      },
    ],
  },
  website_feedback: {
    name: "Website/App Feedback",
    description: "Share your thoughts on our website or app.",
    questions: [
      {
        type: "radio",
        label: "Was our website/app easy to navigate?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      { type: "rating", label: "How intuitive is our design ?" },
      {
        type: "radio",
        label: "Did you encounter any technical issues?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      {
        type: "long",
        label: "What improvements would you suggest for our website/app?",
      },
      {
        type: "rating",
        label: "How satisfied are you with the loading speed ?",
      },
      { type: "long", label: "Any additional comments?" },
    ],
  },
  course_evaluation: {
    name: "Course Evaluation",
    description: "Evaluate the course and provide feedback for improvement.",
    questions: [
      { type: "rating", label: "Overall, how would you rate this course ?" },
      { type: "long", label: "What aspects of the course were most helpful?" },
      { type: "long", label: "Which areas need improvement?" },
      { type: "rating", label: "Rate the instructor's effectiveness " },
      {
        type: "number",
        label: "Approximate hours per week spent on this course",
      },
      {
        type: "radio",
        label: "Would you recommend this course?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
    ],
  },
  employee_performance_review: {
    name: "Employee Performance Review",
    description: "Review the performance of an employee.",
    questions: [
      {
        type: "dropdown",
        label: "Overall performance level",
        options: [
          { value: "Outstanding" },
          { value: "Above Expectations" },
          { value: "Meets Expectations" },
          { value: "Below Expectations" },
        ],
      },
      { type: "rating", label: "Rate the employee’s communication skills " },
      { type: "long", label: "What are the employee’s greatest strengths?" },
      { type: "long", label: "What areas need improvement?" },
      { type: "long", label: "Recommended development opportunities:" },
      {
        type: "rating",
        label:
          "Overall, how would you rate the employee’s performance this period ?",
      },
    ],
  },
  feedback_360: {
    name: "360-Degree Feedback",
    description: "Provide comprehensive feedback on a colleague.",
    questions: [
      { type: "rating", label: "Rate this person’s overall effectiveness " },
      { type: "rating", label: "Rate this person’s teamwork skills " },
      { type: "rating", label: "Rate this person’s communication " },
      { type: "long", label: "What are this person’s strongest qualities?" },
      { type: "long", label: "What is one area for improvement?" },
      {
        type: "radio",
        label: "Would you recommend this person for leadership roles?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
    ],
  },
  manager_feedback: {
    name: "Manager Feedback",
    description: "Give feedback on your manager's performance.",
    questions: [
      {
        type: "radio",
        label: "My manager clearly communicates expectations",
        options: [
          { value: "Strongly Disagree" },
          { value: "Disagree" },
          { value: "Neutral" },
          { value: "Agree" },
          { value: "Strongly Agree" },
        ],
      },
      {
        type: "radio",
        label: "My manager is approachable and receptive",
        options: [
          { value: "Strongly Disagree" },
          { value: "Disagree" },
          { value: "Neutral" },
          { value: "Agree" },
          { value: "Strongly Agree" },
        ],
      },
      { type: "rating", label: "Overall, rate your manager's leadership " },
      {
        type: "long",
        label: "What do you appreciate most about your manager’s leadership?",
      },
      { type: "long", label: "Suggestions for improvement:" },
      {
        type: "dropdown",
        label:
          "How frequently does your manager provide constructive feedback?",
        options: [
          { value: "Regularly" },
          { value: "Occasionally" },
          { value: "Rarely" },
          { value: "Never" },
        ],
      },
    ],
  },
  self_assessment: {
    name: "Self Assessment",
    description: "Assess your own performance and set future goals.",
    questions: [
      { type: "rating", label: "Rate your overall performance this period " },
      {
        type: "long",
        label: "What were your most significant accomplishments?",
      },
      { type: "long", label: "Which areas could you improve upon?" },
      {
        type: "long",
        label: "What goals do you plan to set for the next period?",
      },
      {
        type: "rating",
        label: "How would you rate your time management skills ?",
      },
      { type: "short", label: "Any additional comments on your performance?" },
    ],
  },
  employee_engagement: {
    name: "Employee Engagement Survey",
    description: "Share your level of engagement and satisfaction at work.",
    questions: [
      {
        type: "radio",
        label: "I would recommend this company as a great place to work",
        options: [
          { value: "Strongly Disagree" },
          { value: "Disagree" },
          { value: "Neutral" },
          { value: "Agree" },
          { value: "Strongly Agree" },
        ],
      },
      {
        type: "radio",
        label: "I feel recognized for my contributions",
        options: [
          { value: "Strongly Disagree" },
          { value: "Disagree" },
          { value: "Neutral" },
          { value: "Agree" },
          { value: "Strongly Agree" },
        ],
      },
      { type: "rating", label: "Overall job satisfaction " },
      {
        type: "radio",
        label: "Do you see career advancement opportunities?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      { type: "long", label: "What could improve your work experience?" },
      { type: "rating", label: "How would you rate your work-life balance ?" },
    ],
  },
  exit_interview: {
    name: "Exit Interview",
    description:
      "Provide feedback on your experience as you leave the company.",
    questions: [
      {
        type: "dropdown",
        label: "Primary reason for leaving",
        options: [
          { value: "Better Opportunity" },
          { value: "Career Change" },
          { value: "Relocation" },
          { value: "Personal Reasons" },
          { value: "Other" },
        ],
      },
      { type: "long", label: "What did you like most about working here?" },
      { type: "long", label: "What did you like least about working here?" },
      {
        type: "radio",
        label: "Would you recommend our company as a workplace?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      { type: "long", label: "Additional feedback:" },
      {
        type: "radio",
        label: "How well did you feel supported during your tenure?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
    ],
  },
  onboarding_feedback: {
    name: "Onboarding Feedback",
    description: "Share your experience with the onboarding process.",
    questions: [
      {
        type: "rating",
        label: "Overall, how would you rate your onboarding experience ?",
      },
      {
        type: "radio",
        label: "Was the orientation process clear?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      {
        type: "long",
        label: "What was the most useful part of the onboarding process?",
      },
      { type: "long", label: "Suggestions for improvement:" },
      {
        type: "rating",
        label: "How prepared do you feel to perform your job ?",
      },
      {
        type: "rating",
        label:
          "How satisfied are you with the resources provided during onboarding ?",
      },
    ],
  },
  peer_feedback: {
    name: "Peer Feedback",
    description: "Give feedback on a colleague's performance.",
    questions: [
      { type: "rating", label: "Rate your colleague's collaboration skills " },
      {
        type: "radio",
        label: "Does this colleague communicate effectively?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      {
        type: "long",
        label: "What do you appreciate about working with this colleague?",
      },
      { type: "long", label: "What could they improve?" },
      {
        type: "rating",
        label: "How would you rate their overall performance ?",
      },
      { type: "short", label: "Any additional comments?" },
    ],
  },
  meeting_feedback: {
    name: "Meeting Feedback",
    description: "Provide feedback on the effectiveness of a meeting.",
    questions: [
      {
        type: "radio",
        label: "Was the meeting’s purpose clear?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      { type: "rating", label: "How useful was the meeting ?" },
      {
        type: "radio",
        label: "Was the meeting duration appropriate?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      {
        type: "long",
        label: "What could improve the effectiveness of our meetings?",
      },
      { type: "long", label: "Additional comments:" },
      {
        type: "rating",
        label: "How would you rate the overall organization of the meeting ?",
      },
    ],
  },
  general_feedback: {
    name: "General Feedback",
    description: "Share your general feedback and suggestions.",
    questions: [
      {
        type: "dropdown",
        label: "Type of feedback",
        options: [
          { value: "Complaint" },
          { value: "Suggestion" },
          { value: "Praise" },
          { value: "Other" },
        ],
      },
      { type: "long", label: "Please provide your detailed feedback" },
      { type: "rating", label: "Overall experience " },
      {
        type: "radio",
        label: "May we contact you for follow-up?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      {
        type: "rating",
        label: "How satisfied are you with our response time ?",
      },
      { type: "short", label: "Any additional suggestions?" },
    ],
  },
  client_project_feedback: {
    name: "Client Project Feedback",
    description: "Provide feedback on the project delivery and outcomes.",
    questions: [
      {
        type: "rating",
        label: "Overall, how satisfied are you with the project delivery ?",
      },
      {
        type: "dropdown",
        label: "Did we meet your project requirements?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      { type: "long", label: "What did we do well during the project?" },
      { type: "long", label: "What could have been improved?" },
      {
        type: "radio",
        label: "Would you work with us again?",
        options: [{ value: "Yes" }, { value: "No" }],
      },
      { type: "long", label: "Any additional comments?" },
    ],
  },
};
