import React, { useState } from "react";
import HomeHero from "./HomeHero.jsx";
import HowItWorks from "./HowItWorks.jsx";
import WhyUs from "./WhyUs.jsx";
import UseCases from "./UseCases.jsx";

const HomeComponent = () => {
  return (
    <div>
      <HomeHero />
      <HowItWorks />
      <UseCases />
      <WhyUs />
    </div>
  );
};

export default HomeComponent;
