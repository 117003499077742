import React, { useState, useEffect } from "react";
import Loader from "../shared/Loader";

import "./UseCases.scss";
import { Link } from "react-router-dom";
import Cta from "./Cta";

const fetchPosts = async () => {
  try {
    const response = await fetch(
      "https://hosting.azasoftware.com/wp-json/wp/v2/posts?categories=15&_embed"
    );

    return await response.json();
  } catch (error) {
    console.error("Error fetching use cases:", error);
  }
};

const UseCases = ({ type, className }) => {
  const [articles, setArticles] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  useEffect(() => {
    setIsWaiting(true);
    let isMounted = true;
    const getPosts = async () => {
      try {
        const posts = await fetchPosts();
        if (isMounted) {
          setArticles(posts);
        }
        setIsWaiting(false);
      } catch (e) {
        setIsWaiting(false);
      }
    };
    getPosts();
    return () => {
      isMounted = false;
    };
  }, []);

  // THIS IS BASICALLY FOR CACHING- GOOGLE SOMETIMES IGNORES WORDPRESS CALLS
  if (!articles || !articles.length)
    return (
      <section className="use-cases use-cases__wrapper" id="use-cases">
        <h2>Use Cases</h2>
        <main className="use-cases__items container">
          <div className="use-cases__item">
            <div
              alt="Trainers"
              className="use-cases__item-image"
              style={{ backgroundColor: "lightgray" }}
            ></div>
            <div className="use-cases__item-content">
              <h3 className="use-cases__item-title">Trainers</h3>
              <p>
                <p>
                  Gather attendee feedback to improve future events. Learn what
                  worked, what didn’t, and how to create better experiences.
                </p>
              </p>
              <a href="/use-case/trainers" data-discover="true">
                Read More{" "}
              </a>
            </div>
          </div>
          <div className="use-cases__item">
            <div
              alt="Teachers"
              className="use-cases__item-image"
              style={{ backgroundColor: "lightgray" }}
            ></div>
            <div className="use-cases__item-content">
              <h3 className="use-cases__item-title">Teachers</h3>
              <p>
                <p>
                  Whether you’re a consultant, coach, or freelancer, structured
                  feedback helps you refine your approach and build stronger
                  relationships.
                </p>
              </p>
              <a href="/use-case/teachers" data-discover="true">
                Read More{" "}
              </a>
            </div>
          </div>
          <div className="use-cases__item">
            <div
              alt="Professionals"
              className="use-cases__item-image"
              style={{ backgroundColor: "lightgray" }}
            ></div>
            <div className="use-cases__item-content">
              <h3 className="use-cases__item-title">Professionals</h3>
              <p>
                <p>
                  Understand student engagement and learning gaps. Use feedback
                  to tailor lesson plans, improve classroom experiences, and
                  track student progress over time.
                </p>
              </p>
              <a href="/use-case/professionals" data-discover="true">
                Read More{" "}
              </a>
            </div>
          </div>
          <div className="use-cases__item">
            <div
              alt="Event Organizers"
              className="use-cases__item-image"
              style={{ backgroundColor: "lightgray" }}
            ></div>
            <div className="use-cases__item-content">
              <h3 className="use-cases__item-title">Event Organizers</h3>
              <p>
                <p>
                  {" "}
                  Improve your sessions with direct participant feedback.
                  Measure engagement, identify areas for improvement, and
                  fine-tune your training methods.
                </p>
              </p>
              <a href="/use-case/event-organizers" data-discover="true">
                Read More{" "}
              </a>
            </div>
          </div>
        </main>
      </section>
    );

  return (
    <section className="use-cases use-cases__wrapper">
      <h2>Use Cases</h2>
      <main className="use-cases__items container">
        {!articles || (articles.length === 0 && <Loader size="large" />)}
        {articles.map((article) => (
          <div className="use-cases__item" style={{ height: "100%" }}>
            {article._embedded && article._embedded["wp:featuredmedia"] && (
              <div
                style={{
                  backgroundImage: `url(${article._embedded["wp:featuredmedia"][0].source_url})`,
                }}
                alt={article.title.rendered}
                className="use-cases__item-image"
              />
            )}
            <div className="use-cases__item-content">
              <h3 className="use-cases__item-title">
                <Link to={`/use-case/${article.slug}`}>
                  {article.title.rendered}
                </Link>
              </h3>
              <p
                className="use-cases__item-excerpt"
                dangerouslySetInnerHTML={{ __html: article.excerpt.rendered }}
              ></p>
              <Link to={`/use-case/${article.slug}`}>Read More </Link>
            </div>
          </div>
        ))}
      </main>
    </section>
  );
};

export default UseCases;
