import React from "react";
import Form from "components/shared/Form/form";

import "./metaForm.scss";

const MetaForm = ({ metaData, onChange, formId, isGuestForm }) => {
  const onSubmitImageUpload = (imageURL) => {
    onChange({ ...metaData, cover: imageURL });
  };

  const onSubmitImage = (payload) => {
    var myHeaders = new Headers();

    myHeaders.append("authorization", localStorage.getItem("token"));
    var formdata = new FormData();

    formdata.append("photo", payload);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://photo-api.centralfeedback.com/uploadCover/${formId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          return "Error";
        }
        onSubmitImageUpload(
          `https://photo-api.centralfeedback.com${data.path}`
        );
      })
      .catch((error) => console.log("error", error));
  };

  const fields = [
    {
      name: "name",
      label: "Title",
      type: "text",
      value: metaData?.name || "",
      placeholder: "Enter the form name",
    },
    {
      name: "description",
      label: "Description",
      explainer:
        "A brief summary or detailed explanation of the purpose and contents of the form.",
      type: "textarea",
      value: metaData?.description || "",
      placeholder: "Enter the form description",
    },
    ...(!isGuestForm
      ? [
          {
            type: "file",
            name: "cover",
            label: "Select Image",
            errorMessage: "Invalidimage",
            onFileUpload: onSubmitImage,
          },
        ]
      : []),
  ];
  return (
    <div className="meta-form">
      <Form
        name="meta-form"
        fields={fields}
        onChange={onChange}
        formName=""
        values={metaData}
      />
    </div>
  );
};

export default MetaForm;
