import React from "react";

import "./formField.scss";

const FormField = ({
  name,
  label,
  type,
  value,
  onChange,
  isInvalid,
  onFileUpload,
  errorMessage,
  className = "",
  readOnly,
  explainer = "",
  options = [],
  variant,
  width = "",
  disabled = false,
  component: CustomComponent,
  min = 1,
  max = 5,
  separatorText = "",
}) => {
  return (
    <div
      className={`field-input__wrapper ${type}  ${width} ${
        disabled ? "disabled" : ""
      } ${readOnly ? "readOnly" : ""}`}
    >
      {type !== "separator" && label && (
        <label className="field-input__label" htmlFor={name}>
          {label}
        </label>
      )}
      {type !== "separator" && explainer && <p className="field-input__explainer">{explainer}</p>}
      <div
        className={`field-input field-${name} ${isInvalid && "invalid"} ${
          type || ""
        } ${value?.length ? "has-value" : ""} ${className} ${
          variant || ""
        } ${width}`}
      >
        {(() => {
          switch (type) {
            case "custom":
              return CustomComponent ? (
                <CustomComponent
                  value={value}
                  onChange={onChange}
                  name={name}
                  options={options}
                />
              ) : null;
            case "textarea":
              return (
                <textarea
                  value={value}
                  name={name}
                  id={name}
                  readOnly={readOnly}
                  onChange={(e) => onChange(e.target.value)}
                ></textarea>
              );
            case "dropdown":
              return (
                <select
                  value={value}
                  name={name}
                  id={name}
                  readOnly={readOnly}
                  onChange={(e) => onChange(e.target.value)}
                >
                  {options.map((o, k) => (
                    <option value={o.value} key={`o-${k}-${name}`}>
                      {o.label || o.value}
                    </option>
                  ))}
                </select>
              );
            case "multiple":
              return (
                <div>
                  {options.map((option) => (
                    <div key={option.value} className="field-input__checkbox">
                      <input
                        type="checkbox"
                        id={`${name}-${option.value}`}
                        name={name}
                        value={option.value}
                        className="field-input__checkbox-button"
                        onChange={(e) => {
                          const newValue = e.target.checked
                            ? [...(value || []), option.value]
                            : (value || []).filter((v) => v !== option.value);
                          onChange(newValue);
                        }}
                        checked={value?.includes(option.value)}
                      />
                      <label
                        htmlFor={`${name}-${option.value}`}
                        className="field-input__checkbox-label"
                      >
                        {option.value}
                      </label>
                    </div>
                  ))}
                </div>
              );

            case "radio":
              return (
                <div>
                  {options.map((option) => (
                    <div key={option.value} className="field-input__radio">
                      <input
                        type="radio"
                        id={`${name}-${option.value}`}
                        name={name}
                        value={option.value}
                        className="field-input__radio-button"
                        onChange={(e) => onChange(option.value)}
                      />
                      <label
                        htmlFor={`${name}-${option.value}`}
                        className="field-input__radio-label"
                      >
                        {option.value}
                      </label>
                    </div>
                  ))}
                </div>
              );
            case "file":
              return (
                <input
                  id={name}
                  name={name}
                  type={type}
                  readOnly={readOnly}
                  onChange={(e) => {
                    onFileUpload(e.target.files[0]);
                  }}
                />
              );
            case "checkbox":
              return (
                <div className="field-input__checkbox-wrapper">
                <input
                  id={name}
                  name={name}
                  type={type}
                  checked={!!value}
                  readOnly={readOnly}
                  onChange={(e) => onChange(e.target.checked)}
                />
                </div>
              );
            case "rating":
              return (
                <div className="rating-input">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      type="button"
                      className={`rating-star ${value >= star ? "active" : ""}`}
                      onClick={() => !readOnly && onChange(star)}
                      disabled={readOnly}
                    >
                      ★
                    </button>
                  ))}
                </div>
              );
            case "scale":
              return (
                <div className="scale-input">
                  {Array.from({ length: max - min + 1 }, (_, i) => i + min).map((num) => (
                    <button
                      key={num}
                      type="button"
                      className={`scale-number ${value === num ? "active" : ""}`}
                      onClick={() => !readOnly && onChange(num)}
                      disabled={readOnly}
                    >
                      {num}
                    </button>
                  ))}
                </div>
              );
            case "separator":
              return (
                <>
                  {separatorText && <h3>{separatorText}</h3>}
                  <hr />
                </>
              );
            default:
              return (
                <input
                  id={name}
                  name={name}
                  type={type}
                  value={value}
                  readOnly={readOnly}
                  onChange={(e) => onChange(e.target.value)}
                />
              );
          }
        })()}

        {isInvalid && errorMessage && (
          <span className="error-message">{errorMessage}</span>
        )}
      </div>{" "}
    </div>
  );
};

export default FormField;
