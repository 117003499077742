import React from "react";
import { Modal, Alert } from "react-bootstrap";
import RegisterForm from "./RegisterForm";
import userApi from "api/userApi";
import ToastService from "shared/toastService";
import UIButton from "components/shared/Button";
import { useDispatch } from "react-redux";
import { postUserData, requestUserData } from "reduxCode/actions/user";
import authAPI from "api/authApi";
import { setStatus, setToken } from "reduxCode/actions/auth";
import STATUS_TYPES from "constants/statusTypes";
import userAPI from "api/userApi";

const RegisterModal = ({ formData, onClose, onSuccess }) => {
  const [userData, setUserData] = React.useState({});
  const [alert, setAlert] = React.useState({ type: "", message: "" });

  const dispatch = useDispatch();

  const handleRegister = async () => {
    setAlert({ type: "", message: "" }); // Clear previous alerts
    try {
      const result = await userAPI.postUserData({ ...userData, formData });

      setAlert({
        type: "success",
        message: "Registration successful. Redirecting you to your forms page.",
      });

      const jsonPayload = await authAPI.doLogin(userData);
      dispatch(setToken(jsonPayload.token));
      dispatch(setStatus(STATUS_TYPES.LOADED));

      setTimeout(() => {
        window.location.href = "/forms";
      }, 1500); // Give time to show success message

      return dispatch(requestUserData(jsonPayload.token));
    } catch (error) {
      console.error("Login failed", error);
      const msg = await error;
      setAlert({
        type: "danger",
        message:
          msg.message == "Duplicate Entry"
            ? "You already have an account"
            : "Your sign up could not be completed.",
      });
    }
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      backdrop="static" // Prevent closing by clicking outside
      keyboard={false} // Prevent closing with escape key
      centered
      className="form-register-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create your account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="form-register-explainer">
          Set up an account to be able to view the responses you collect and
          display your personal links along with the form.
        </p>
        <RegisterForm
          doPostRegister={handleRegister}
          minimal={true}
          customText=""
          userData={userData}
          setUserData={setUserData}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column w-100">
        {alert.message && (
          <Alert variant={alert.type} className="w-100 mb-3">
            {alert.message}
          </Alert>
        )}
        <UIButton
          text="Publish Form"
          type="primary"
          onClick={handleRegister}
          size="large"
          className={"publish w-100"}
          disabled={
            !userData.firstName ||
            !userData.lastName ||
            !userData.email ||
            !userData.password ||
            !userData.tac
          }
        />
      </Modal.Footer>
    </Modal>
  );
};

export default RegisterModal;
