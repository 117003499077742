
const updateMetaTags = (metadata) => {
    document.title = metadata.title;
    
    const metaTags = {
      description: metadata.description,
      keywords: metadata.keywords,
      robots: metadata.robots,
      'og:title': metadata.title,
      'og:description': metadata.description,
      'og:image': metadata.image,
      'twitter:card': 'summary_large_image',
      'twitter:title': metadata.title,
      'twitter:description': metadata.description,
      'twitter:image': metadata.image
    };
  
    Object.entries(metaTags).forEach(([name, content]) => {
      if (!content) return;
      
      // Try to find existing meta tag
      let element = document.querySelector(`meta[property="${name}"]`) || 
                   document.querySelector(`meta[name="${name}"]`);
      
      // Create new meta tag if it doesn't exist
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute(name.includes('og:') ? 'property' : 'name', name);
        document.head.appendChild(element);
      }
      
      element.setAttribute('content', content);
    });
  };
  
  export default updateMetaTags;