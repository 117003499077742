import React, { useEffect, useState } from "react";
import Button from "components/shared/Button";
import FormField from "components/shared/Form/formField";
import Layout from "components/shared/Layout";
import MetaForm from "./MetaForm"; // Importing the MetaForm component

import "./myForms.scss";
import formApi from "api/formApi";
import Box from "components/shared/Box";
import ToastService from "shared/toastService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import amplitude from "shared/analytics";

import "./formbuilder.scss";
import Loader from "components/shared/Loader";
import { Link } from "react-router-dom";
import { TEMPLATES } from "./formTemplates.const";
import RegisterModal from "../Register/RegisterModal";
import updateMetaTags from "helpers/meta";

const FormBuilderWithMeta = ({ formId }) => {
  const { template } = useParams();
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [formToSubmit, setFormToSubmit] = useState(null);
  const [fields, setFields] = useState([]);
  const [fieldType, setFieldType] = useState("short");
  const [label, setLabel] = useState("");
  const [options, setOptions] = useState(["Option 1", "Option 2"]); // Initialize with one empty option
  const [explainer, setExplainer] = useState(""); // Add state for explainer
  const [metaData, setMetaData] = useState({
    submitButtonText: "Submit Form", // Add default submit button text
  }); // State for metadata
  const [visibility, setVisibility] = useState(1);
  const [isPending, setIsPending] = useState(true);
  const [formData, setFormData] = useState({});
  const [pendingSecondSubmit, setPendingSecondSubmit] = useState(false);
  const [shareEmail, setShareEmail] = useState(""); // State for share email
  const myUserId = useSelector((state) => state?.user?.data?.id || "");
  const navigate = useNavigate();
  const [isAddingField, setIsAddingField] = useState(false);
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(template || ""); // Add state for selected template
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(5);
  const [separatorText, setSeparatorText] = useState("");

  const toggleSettingsAccordion = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const isNewForm = formId === "new";
  const isGuestForm = isNewForm && !myUserId;

  const getFormData = async () => {
    try {
      if (formId === "new") {
        setIsPending(false);
        return;
      }
      const form = await formApi.getFormById({ formId });

      if (form.owner != myUserId && form.coAuthor != myUserId) {
        navigate("/forms");
        ToastService.makeErrorToast({
          title: "Form Error",
          text: `You cannot edit this form.`,
        });
      }
      setFormData(form);
      setVisibility(form.visibility);

      if (form.owner) setFields(JSON.parse(form.questions));
      setMetaData({ ...form });
      setIsPending(false);
    } catch (e) {
      ToastService.makeErrorToast({
        title: "Form Error",
        text: `We couldn't find your form.`,
      });
      setIsPending(false);
    }
  };

  useEffect(() => {
    getFormData();

    updateMetaTags({
      title: "Form Builder - Central Feedback",
    });
  }, []);

  useEffect(() => {
    if (template && TEMPLATES[template]) {
      if (myUserId && formId == "new") {
        alert("You can use our templates from the forms page.");
        window.location.href = "/forms";
      }
      setFields(TEMPLATES[template].questions);
      setMetaData({
        ...metaData,
        name: TEMPLATES[template]?.name,
        description: TEMPLATES[template]?.description || "Form Description",
      });

      updateMetaTags({
        title: `Form Builder - ${TEMPLATES[template]?.name} Template | Central Feedback`,
        description: `Create your form using the ${TEMPLATES[template]?.name} template now with just a few clicks.`,
      });
    }
  }, [template]);

  useEffect(() => {
    if (selectedTemplate && TEMPLATES[selectedTemplate]) {
      setFields(TEMPLATES[selectedTemplate].questions);
      setMetaData({
        ...metaData,
        name: TEMPLATES[selectedTemplate].name,
        description:
          TEMPLATES[selectedTemplate].description || "Form Description",
      });
    }
  }, [selectedTemplate]);

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleDeleteOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleAddField = () => {
    const newField = {
      type: fieldType,
      name: "q" + fields.length,
      min,
      max,
      separatorText,
    };

    if (fieldType !== "separator") {
      newField.label = label;
      newField.explainer = explainer;
    }

    amplitude?.track({
      event_type: "ADD_FORM_FIELD",
      properties: {
        path: window.location.pathname,
        fieldType: fieldType,
      },
    });

    if (
      fieldType === "dropdown" ||
      fieldType === "radio" ||
      fieldType === "multiple"
    ) {
      newField.options = options.map((option) => ({ value: option.trim() }));
    }

    setFields([...fields, newField]);

    setNotAddingField();
  };

  const setNotAddingField = () => {
    setFieldType("short");
    setLabel("");
    setSeparatorText("");
    setOptions(["Option 1", "Option 2"]); // Reset options
    setExplainer(""); // Reset explainer
    setIsAddingField(false);
  };

  const handleDeleteField = (index) => {
    setFields(fields.filter((_, i) => i !== index));
  };

  const handleMoveFieldUp = (index) => {
    if (index === 0) return;
    const newFields = [...fields];
    [newFields[index - 1], newFields[index]] = [
      newFields[index],
      newFields[index - 1],
    ];
    setFields(newFields);
  };

  const handleMoveFieldDown = (index) => {
    if (index === fields.length - 1) return;
    const newFields = [...fields];
    [newFields[index + 1], newFields[index]] = [
      newFields[index],
      newFields[index + 1],
    ];
    setFields(newFields);
  };

  const handleMetaSubmit = (meta) => {
    setMetaData(meta); // Update metadata when submitted
  };

  const handleFinalSubmit = async () => {
    const finalFormData = {
      ...metaData,
      questions: JSON.stringify(fields),
      submitButtonText: metaData.submitButtonText || "Submit Form",
      showProfileCard: metaData.showProfileCard !== false, // Ensure showProfileCard is included
    };

    // Remove the alert and simplify the guest form check
    if (!myUserId || isGuestForm) {
      setFormToSubmit(finalFormData);
      setShowRegisterModal(true);
      return;
    }

    if (label?.length > 0 && pendingSecondSubmit == false) {
      setPendingSecondSubmit(true);

      return true;
    }

    if (pendingSecondSubmit) {
      setPendingSecondSubmit(false);
      setLabel("");
    }

    await formApi.patchFormData({
      formId,
      ...finalFormData,
    });

    ToastService.makeSuccessToast({
      title: "Form Saved",
      text: (
        <span>
          Your form has been saved.
          <br /> <Link to={`/${formData?.ownerSlug}/${formId}`}>View Form</Link>
        </span>
      ),
    });
  };

  const handlePublishOrUnpublishForm = async () => {
    const newVisibility = visibility === 1 ? 0 : 1;
    const newVisibilityWord = `${newVisibility ? "" : "un"}publish`;

    const patchData = {
      visibility: newVisibility,
    };

    try {
      await formApi.patchFormData({
        formId,
        ...patchData,
      });

      setVisibility(newVisibility);

      ToastService.makeSuccessToast({
        title: `Form ${newVisibilityWord}ed`,
        text: `Your has been ${newVisibilityWord}ed.`,
      });
    } catch (e) {
      ToastService.makeErrorToast({
        title: "Form issue",
        text: `Your form could not be ${newVisibilityWord}ed.`,
      });
    }
  };

  const handleShareForm = async () => {
    try {
      await formApi.shareForm({ formId, email: shareEmail });
      ToastService.makeSuccessToast({
        title: "Form Shared",
        text: "The form has been shared successfully!",
      });
      setShareEmail("");
    } catch (e) {
      ToastService.makeErrorToast({
        title: "Share Error",
        text: "There was an error sharing the form.",
      });
    }
  };

  const onMetaChange = (values) => {
    if (values.cover?.includes("fakepath")) return false;
    setMetaData({ ...values });
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  if (isPending) {
    return <Loader size="largest" />;
  }

  return (
    <div className="form-builder">
      <div className={`box small-shadow form-settings `}>
        <h1>Form Builder</h1>
        <hr />
        <div
          className={`settings-accordion ${
            isSettingsOpen ? "accordion-open" : "accordion-closed"
          }`}
        >
          {isGuestForm ? (
            <>
              <label className="field-input__label">Select Template</label>
              <p className="template__explainer">
                Try out any template, you can customize your questions as much
                as you want.
              </p>
              <div className="field-input short">
                <select
                  value={selectedTemplate}
                  onChange={handleTemplateChange}
                >
                  <option value="">Select a template</option>
                  {Object.keys(TEMPLATES).map((key) => (
                    <option key={key} value={key}>
                      {TEMPLATES[key].name}
                    </option>
                  ))}
                </select>
              </div>
              <hr />
            </>
          ) : (
            ""
          )}
          <MetaForm
            isGuestForm={isGuestForm}
            metaData={metaData}
            onChange={onMetaChange}
            formId={formId}
          />
          {!isGuestForm && (
            <div className="form-builder__share-form">
              <label className="field-input__label">Share with:</label>
              <div className="field-input field-undefined short">
                <input
                  type="email"
                  value={shareEmail}
                  onChange={(e) => setShareEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </div>
              <Button
                text="Share"
                onClick={handleShareForm}
                style={{ padding: "5px 10px" }}
                type="secondary"
                size="small"
                className={"share-button"}
                disabled={!shareEmail}
              ></Button>
            </div>
          )}
          <div className="form-builder__submit-text">
            <label className="field-input__label">Submit Button Text:</label>
            <div className="field-input field-undefined short">
              <input
                type="text"
                value={metaData.submitButtonText || "Submit Form"}
                onChange={(e) =>
                  setMetaData({
                    ...metaData,
                    submitButtonText: e.target.value,
                  })
                }
                placeholder="Enter submit button text"
              />
            </div>
            <FormField
              type="checkbox"
              name="showProfileCard"
              label="Show Profile Card"
              explainer={
                <label htmlFor="showProfileCard">
                  Show your profile information at the top of the form
                </label>
              }
              value={!!metaData.showProfileCard}
              onChange={(checked) =>
                setMetaData({
                  ...metaData,
                  showProfileCard: !!checked,
                })
              }
            />
          </div>
        </div>
        <div className="float-buttons-down">
          <hr />
          <div className="d-flex justify-content-between ">
            <Button
              text={isGuestForm ? "Publish form now" : "Save Form"}
              onClick={handleFinalSubmit}
              style={{ padding: "5px 10px" }}
              type="primary"
              size="full-width"
              disabled={!fields.length || !metaData.name.length}
            ></Button>
          </div>
          {!isGuestForm && (
            <div className="form-settings__split-buttons">
              <Button
                type="ternary"
                action="link"
                to={`/${formData?.ownerSlug}/${formId}`}
                text="Preview"
                size="large"
                target="_blank"
              />
              <Button
                text={visibility === 1 ? "Unpublish" : "Publish"}
                onClick={handlePublishOrUnpublishForm}
                style={{ padding: "5px 10px" }}
                type="ternary"
                size="large"
                disabled={!fields.length || !metaData.name.length}
              />
            </div>
          )}
          {pendingSecondSubmit ? (
            <div className="alert alert-warning mt-3" role="alert">
              Your last field hasn't been added to the form. Proceed anyway?
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="form-settings__open-cta"
          onClick={toggleSettingsAccordion}
        >
          {isSettingsOpen ? "Hide" : "Show"} settings
        </div>
      </div>

      <Box
        className="preview-box"
        size="big"
        cover={metaData.cover}
        title={{ type: "accent", text: metaData.name + "" }}
      >
        {metaData.description && (
          <p className="form__description feedback-form__description">
            {metaData.description}
          </p>
        )}
        <div className="feedback-form__questions">
          {fields.map((field, index) => (
            <div
              key={`field-${index}`}
              className="question-wrapper"
              style={{ position: "relative" }}
            >
              <FormField {...field} name={"q-" + index} onChange={() => {}} />
              <div className="feedback-form__field-actions">
                <Button
                  onClick={() => handleMoveFieldUp(index)}
                  type="secondary"
                  className={"form-builder__move-up"}
                  text=""
                ></Button>
                <Button
                  onClick={() => handleMoveFieldDown(index)}
                  type="secondary"
                  text=""
                  className={"form-builder__move-down"}
                >
                  Down
                </Button>
                <Button
                  onClick={() => handleDeleteField(index)}
                  className={"form-builder__delete"}
                  type="secondary"
                ></Button>
              </div>
            </div>
          ))}
        </div>

        {isAddingField ? (
          <div className="box small-shadow add-field-box">
            <div className="form-builder__add-field">
              <label className="field-input__label">Type</label>
              <div className="field-input field-undefined short">
                <select
                  value={fieldType}
                  onChange={(e) => setFieldType(e.target.value)}
                >
                  <option value="short">Short Text</option>
                  <option value="long">Long Text</option>
                  <option value="rating">Rating (5 stars)</option>
                  <option value="scale">Scale</option>
                  <option value="radio">Single Choice</option>
                  <option value="multiple">Multiple Choice</option>
                  <option value="dropdown">Dropdown</option>
                  <option value="email">Email</option>
                  <option value="separator">Separator</option>
                </select>
              </div>
              {fieldType !== "separator" && (
                <>
                  <label className="field-input__label">Field Title</label>
                  <p className="field-input__explainer">
                    This will be the title of the field, the actual question
                    that you want an answer to.
                  </p>
                  <div className="field-input field-text short">
                    <input
                      type="text"
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                    />
                  </div>
                  <label className="field-input__label">Description:</label>
                  <p className="field-input__explainer">
                    Optional. You can add more information or describe your
                    question.
                  </p>
                  <div className="field-input field-text short">
                    <input
                      type="text"
                      value={explainer}
                      onChange={(e) => setExplainer(e.target.value)}
                    />
                  </div>
                </>
              )}
              {fieldType === "scale" && (
                <>
                  <label className="field-input__label">Minimum Value</label>
                  <div className="field-input field-text short">
                    <input
                      type="number"
                      value={min}
                      onChange={(e) => setMin(Number(e.target.value))}
                    />
                  </div>
                  <label className="field-input__label">Maximum Value</label>
                  <div className="field-input field-text short">
                    <input
                      type="number"
                      value={max}
                      onChange={(e) => setMax(Number(e.target.value))}
                    />
                  </div>
                </>
              )}
              {fieldType === "separator" && (
                <>
                  <label className="field-input__label">Separator Text</label>
                  <div className="field-input__explainer">
                    Optional. Leave it blank if you just want a horizontal line
                  </div>
                  <div className="field-input field-text short">
                    <input
                      type="text"
                      value={separatorText}
                      onChange={(e) => setSeparatorText(e.target.value)}
                    />
                  </div>
                </>
              )}
              {(fieldType === "dropdown" ||
                fieldType === "radio" ||
                fieldType === "multiple") && (
                <>
                  <label className="field-input__label">
                    Enter the options below:
                  </label>
                  {options.map((option, index) => (
                    <div key={index} className="field-input choice-controller">
                      <input
                        type="text"
                        value={option}
                        onChange={(e) =>
                          handleOptionChange(index, e.target.value)
                        }
                      />
                      <Button
                        onClick={() => handleDeleteOption(index)}
                        size="small"
                        type="secondary"
                        className="delete-option"
                      />
                    </div>
                  ))}
                  <Button
                    text="+ Add Option"
                    onClick={handleAddOption}
                    type="text"
                    size="small"
                    className="add-option"
                  />
                </>
              )}
              <div className="add-field">
                <Button
                  text="Cancel"
                  onClick={setNotAddingField}
                  style={{ padding: "5px 10px" }}
                  type="secondary"
                  size="medium"
                  className={"cancel-button"}
                ></Button>
                <Button
                  text="Add To Form"
                  onClick={
                    !!label || fieldType === "separator"
                      ? handleAddField
                      : () => {}
                  }
                  style={{ padding: "5px 10px" }}
                  type="primary"
                  size="medium"
                  disabled={!label && fieldType !== "separator"}
                  className={
                    !!label || fieldType === "separator" ? "disabled" : ""
                  }
                ></Button>
              </div>
            </div>
          </div>
        ) : (
          <Button
            text={"Add Field"}
            onClick={() => setIsAddingField(true)}
            type="secondary"
            size="large"
            className={"add-field-prompt"}
          />
        )}

        <div className="mobile-only-button">
          <hr />
          <p></p>
          <Button
            text={isGuestForm ? "Publish form now" : "Save Form"}
            onClick={handleFinalSubmit}
            style={{ padding: "5px 10px" }}
            type="primary"
            size="full-width"
            disabled={!fields.length || !metaData.name.length}
          ></Button>
          {pendingSecondSubmit ? (
            <div className="alert alert-warning mt-3" role="alert">
              Your last field hasn't been added to the form. Proceed anyway?
            </div>
          ) : (
            ""
          )}
        </div>
      </Box>
      {showRegisterModal && (
        <RegisterModal
          formData={formToSubmit}
          onClose={() => {
            setShowRegisterModal(false);
          }}
        />
      )}
    </div>
  );
};

export default FormBuilderWithMeta;
